import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { Box, chakra, Grid, Heading } from "@chakra-ui/react"

import Section from "./Section"
import Itinerary from "./Itinerary"

const Base = ({ base, boatName }) => {
  if (!base) {
    return null
  }
  return (
    <>
      <Section headline="Harbour" divider>
        <Box
          sx={{
            width: ["100", "90%", "80%"],
            mx: "auto",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
              base.name
            )}+${encodeURI(base.city)}+${encodeURI(base.city)}+${encodeURI(
              base.country
            )}`}
          >
            <chakra.img
              // eslint-disable-next-line react/no-array-index-key
              src={base.mapURL}
              alt={`${boatName} harbour map`}
              mt={[12, 16]}
              sx={{
                maxWidth: "100%",
              }}
            />
            <chakra.span
              sx={{
                position: "relative",
                color: "muted",
                fontSize: ["xs", "sm"],
                display: "block",
              }}
            >
              Click to browse in a larger map
            </chakra.span>
          </a>
          <Heading
            as="h3"
            variant="h3"
            size="lg"
            mt={[12, 16]}
            mb={[6, 8]}
            sx={{ color: "primary", gridColumn: "1 / -1" }}
          >
            {base.name}
          </Heading>
          {base.content && base.content.data.base_details && (
            <Box sx={{ "p:last-of-type": { mb: 0 } }}>
              <RichText render={base.content.data.base_details} />
            </Box>
          )}
        </Box>
      </Section>
      {base.content &&
        Array.isArray(base.content.data.body) &&
        base.content.data.body.length > 0 && (
          <Section
            my={[12, 16]}
            headline="Trips you can take from this harbour"
            divider
          >
            <Grid
              mt={8}
              sx={{
                width: ["100", "90%", "80%"],
                gridColumnGap: 16,
                mx: "auto",
                gridTemplateColumns: ["1fr", "1fr 1fr"],
                h3: { color: "primary" },
              }}
            >
              {base.content.data.body.map((b, i) => (
                <Itinerary itinerary={b} key={`itinerary-${i}`} />
              ))}
            </Grid>
          </Section>
        )}
    </>
  )
}

Base.propTypes = {
  boatName: PropTypes.string.isRequired,
}

export default Base
