import { Grid, Heading } from "@chakra-ui/react"
import React from "react"

import { PriceText } from "./PriceTag"

const Col = ({ extras, title }) => (
  <Grid
    sx={{
      gridTemplateColumns: ["auto", "auto auto"],
      alignContent: "start",
      alignItems: "baseline",
      gridColumnGap: 8,
      mb: 4,
    }}
  >
    <Heading
      as="h4"
      variant="h4"
      size="lg"
      mt={[8, 12]}
      sx={{ gridColumn: "1 / -1", textAlign: "center", mb: [0, 4] }}
    >
      {title}
    </Heading>
    {extras.map((e) => (
      <React.Fragment key={e.id}>
        <Heading
          as="h6"
          variant="h6"
          size="sm"
          sx={{
            gridColumn: ["1/-1", "1"],
            color: "primary",
            mt: [4, 2],
            mb: 0,
          }}
          isTruncated
          noOfLines={2}
        >
          {e.name}
        </Heading>

        <span sx={{ ml: [0, 4] }}>
          <PriceText currency="EUR" price={e.price} />
          <span>{` ${e.unit}`}</span>
        </span>
      </React.Fragment>
    ))}
  </Grid>
)

const Extras = ({ boatData: { extras } }) => {
  const obligatories = extras
    .filter((e) => e.obligatory)
    .sort((a, b) => (a.price < b.price ? -1 : 1))
  const optionals = extras
    .filter((e) => !e.obligatory)
    .sort((a, b) => (a.price < b.price ? -1 : 1))
  return (
    <>
      {obligatories.length > 0 && (
        <Col extras={obligatories} title="Mandatory" />
      )}
      {optionals.length > 0 && <Col extras={optionals} title="Optional" />}
    </>
  )
}

export default Extras
