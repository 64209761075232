import { useEffect, useState } from "react"

const useInView = (options) => {
  const [inView, setInView] = useState(true)
  const [targetRef, setTargetRef] = useState(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!targetRef || !entry) return

        // MDN says that the ratio should be between 0 and 1, but
        // looks like safari iOS goes above 1... so we do >= 1
        setInView(entry.intersectionRatio >= 1)
      },
      { threshold: 1, ...options }
    )
    targetRef && observer.observe(targetRef)

    return () => {
      if (targetRef) observer.unobserve(targetRef)
    }
  }, [options, targetRef])

  return [
    inView,
    (el) => {
      setTargetRef(el)
    },
  ]
}

export default useInView
