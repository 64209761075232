import axios from "axios"
import { useQuery } from "react-query"
import { Boat } from "../types/boat"

const useBoat = (boatUid: string) => {
  const fetchBoat = () => axios.get<Boat>("/api/boats-get", { params: { uid: boatUid } })
  const { data: boatResponse, isLoading: isBoatLoading } = useQuery(["fetchBoat", boatUid], fetchBoat, {
    staleTime: 6 * 60 * 60 * 1000,
    enabled: !!boatUid,
  })
  const boat = boatResponse?.data
  return { isBoatLoading, boat }
}

export default useBoat
