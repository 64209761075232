import React from "react"
import {
  Box,
  Button,
  chakra,
  Flex,
  Icon,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  FormErrorMessage,
  Heading,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { FaRegShareSquare } from "react-icons/fa"
import { HiBadgeCheck } from "react-icons/hi"

import { Calendar, Email } from "./Icons"
import Link from "./Link"
import Section from "./Section"
import { PriceTag } from "./PriceTag"
import Discount from "./Discount"
import OfferDate from "./OfferDate"
import { useToast } from "src/lib/hooks/useToast"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"

const BoatCTA = React.forwardRef(({ boat, offer }, ref) => {
  const modalProps = useDisclosure()
  const toast = useToast()
  const [emailFromSession, setEmailFromSession] = useLocalStorageState("email")
  const [email, setEmail] = React.useState(emailFromSession || "")

  const shareBoat = () => {
    // eslint-disable-next-line no-undef
    if (navigator.share) {
      // eslint-disable-next-line no-undef
      navigator.share({
        title: `${boat.modelName} | Book Now`,
        // eslint-disable-next-line
        url: location.href,
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const dcq = window._dcq
      if (dcq) {
        dcq.push(["identify", { email }])
        dcq.push([
          "track",
          "Email me a boat",
          {
            email,
            boat_uid: boat.uid,
            boat_name: boat.modelName,
          },
        ])
      }
      setEmail("")
      setEmailFromSession(email)
      modalProps.onClose()
      toast({
        status: "success",
        description: `We've sent details of "${boat.modelName}" to ${email}`,
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Section
        headline={boat.modelName}
        mt={[8, 16]}
        mb={12}
        sx={{
          color: "muted",
          "a, p": {
            textAlign: "center",
            my: 1,
          },
          a: {
            textAlign: "center",
          },
        }}
      >
        {offer && (
          <chakra.p
            sx={{
              color: "primary",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              my: "2rem !important",
            }}
          >
            <OfferDate offer={offer} sx={{ width: ["100%", "auto"] }} />
            <chakra.span sx={{ mx: 1, color: "muted", display: ["none", "inline"] }}>|</chakra.span>
            <chakra.span sx={{ display: ["block", "none"], flexBasis: "100%", height: 0 }} />
            <PriceTag currency={offer.currency} startPrice={offer.startPrice} price={offer.discountedPrice} />
            <Discount sx={{ ml: 3 }} startPrice={offer.startPrice} price={offer.discountedPrice} />
          </chakra.p>
        )}

        <Flex my={6} justify="center">
          <Flex align="center" flexDir="column">
            <Button
              as={Link}
              size="lg"
              to={
                offer ? `/boats/${boat.uid}/reserve-details?offerId=${offer.id}` : `/boats/${boat.uid}/reserve-details`
              }
              leftIcon={<Calendar boxSize={6} display={["none", "inline-block"]} />}
            >
              INQUIRE NOW
            </Button>
            <Flex align="center">
              <Box as={HiBadgeCheck} color="primary" mr={1} />
              <Text color="black" fontSize="0.7rem">
                Best price on the market
              </Text>
            </Flex>
          </Flex>
          <p ref={ref} />
        </Flex>

        <Link
          to={offer ? `/boats/${boat.uid}/reserve-details?offerId=${offer.id}` : `/boats/${boat.uid}/reserve-details`}
          sx={{ display: "block", mt: 8, color: "muted", _hover: { color: "primary" } }}
        >
          Reserve your boat for 72 hours for free
        </Link>
        <p>You won&apos;t be charged</p>

        <Flex justify="center">
          <chakra.span
            role="button"
            tabIndex={0}
            sx={{
              ml: 3,
              variant: "links.primary",
              display: ["block", "none"],
              textAlign: "center",
            }}
          >
            <Flex onClick={shareBoat} onKeyPress={shareBoat} align="baseline">
              Share this boat
              <Icon as={FaRegShareSquare} sx={{ ml: 2, top: ".125em", position: "relative" }} />
            </Flex>
          </chakra.span>

          <chakra.span
            role="button"
            tabIndex={0}
            sx={{
              ml: 3,
              variant: "links.primary",
              display: ["none", "block"],
              textAlign: "center",
            }}
          >
            <Flex onClick={modalProps.onOpen} align="baseline">
              Email me this boat
              <Icon as={FaRegShareSquare} sx={{ ml: 2, top: ".125em", position: "relative" }} />
            </Flex>
          </chakra.span>
        </Flex>
      </Section>
      <Modal {...modalProps} isCentered>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalCloseButton />
          <ModalBody py={8}>
            <Heading as="h4" variant="h4" size="md">
              Share "{boat.modelName}" by email
            </Heading>
            <Box as="form" onSubmit={onSubmit}>
              <Stack direction={{ base: "column", sm: "row" }} spacing={{ base: 0, sm: 4 }}>
                <Stack py={5} w="100%">
                  <Text fontSize={16} fontWeight="bold" lineHeight="25px">
                    E-Mail
                  </Text>
                  <FormControl isRequired>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" children={<Email boxSize={5} color="primary" />} />
                      <Input
                        placeholder="Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        borderRadius="none"
                      />
                    </InputGroup>
                    <FormErrorMessage>This field is required</FormErrorMessage>
                  </FormControl>
                </Stack>
              </Stack>
              <Flex justify="center">
                <Button size="lg" type="submit">
                  SUBMIT
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
})

BoatCTA.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  boat: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  offer: PropTypes.object,
}

BoatCTA.defaultProps = {
  offer: null,
}

export default BoatCTA
