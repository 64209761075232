import React from "react"

export const FAQS: { question: string; answer: React.ReactNode }[] = [
  {
    question: "Are there any other costs I should be aware of?",
    answer:
      "The two main additional costs are marina fees and fuel costs. Marina fees are paid to the marina any time you dock there overnight. The price per night will vary by marina, and you can usually find out how much it is by contacting them directly in advance. Fuel costs also vary depending on the type of boat you charter and how much you have to use the motor. For example if you intend to rent a sailboat and sail the majority of the way your fuel costs  will be very low, however if you rent a motorboat then your fuel costs are likely to be much higher.",
  },
  {
    question: "What is a Transit Log?",
    answer: (
      <>
        A Transit Log is a payment that covers various important items on your sailing trip. These can include:
        cleaning, bed linen, towels, internet connection, base marina fees and tourist tax. The transit log is unique to
        the boat and will vary based on several factors including location and charter operator.
        <br />
        <br />
        On selecting your boat, the team at HighSails can tell you exactly what is included in your booking’s Transit
        Log.
      </>
    ),
  },
  {
    question: "When can I get in touch with the skipper?",
    answer:
      "Following the booking confirmation the skipper will be booked, however it is not common to get in touch with the skipper until 1 month before arrival as skippers can change. If you would like to contact the skipper you can either do so through us or directly with the charter operator.",
  },
  {
    question: "What happens when I reserve a boat?",
    answer:
      "Once you have submitted your reservation request you will receive a reservation notification email. At this point your boat is reserved for you for 48 hours and will not cost you anything. After the 48 hours has passed the boat will no longer be reserved for you unless you make the initial payment to confirm your reservation. Once we receive this we will confirm your booking and send you an email with the full booking details.",
  },
  {
    question: "What is the cancellation policy?",
    answer: (
      <>
        A full refund is given for any cancellations made within 24 hours of booking. For cancellations between 2 and 7
        days after booking, a 12% handling fee is charged. If you cancel any time after 7 days but no later than 30 days
        prior to check-in, 50% of the charter price is refunded. Reservations cancelled less than 1 month before
        check-in are not eligible for any refund. All refunds will be processed within 14 business days from the time of
        cancellation.
        <br />
        <br />
        Sometimes operators have their own cancellation policy so it is important to check the individual booking form.
        If you have any further questions please contact our representatives at HighSails who will be happy to answer
        your questions.
      </>
    ),
  },
  {
    question: "What is the security deposit?",
    answer:
      "The security deposit is a payment made to cover any passengers, including yourself, for any accidental damage or loss. The size of the security deposit will vary depending on the make, model and cost of the boat. The amount required for the security deposit can be found in the booking form.",
  },
  {
    question: "When is the security deposit paid?",
    answer:
      "The security deposit is paid prior to embarkation, and depending on the charter operator can be paid by credit card but in most cases is paid in cash. It will be returned to you in full at the end of your trip provided the boat is returned in the same condition in which you received it.",
  },
  {
    question: "Do I need a boat license?",
    answer:
      "To charter any of our boats one of you or your crew members is required to have a valid navigation and VHF license. If nobody in your party has a boat license you can hire a skipper who will be responsible for any sailing and navigation. Skippers can be added at checkout.",
  },
  {
    question: "What is your COVID policy?",
    answer:
      "If your trip is affected by Covid-19 after payment has been made – e.g. you can’t get to your starting destination because borders are closed – most of the boats listed on our site can be rescheduled to a later date and some are eligible for a full refund. Contact us for the full terms and conditions for a particular boat.",
  },
  {
    question: "What is the cleaning policy between rentals?",
    answer:
      "Staff are working hard to ensure boats and crew are Covid-19 guideline compliant. There will be a full clean on all boats before the next group embark on the charter.",
  },
  {
    question: "Can I message the charter company before booking a boat?",
    answer:
      "If you have any questions for the charter company before booking, we will speak with them on your behalf. Tell us what you’d like to know and we will find out and get back to you as soon as we can.",
  },
  {
    question: "When can I get in touch with my skipper?",
    answer:
      "You can get in touch with your skipper once your booking has been made, however we typically contact skippers directly on your behalf. Please also note that skippers are usually only allocated around 30 days prior to embarkation.",
  },
  {
    question: "When will I receive the charter’s contact details?",
    answer:
      "Once your booking is confirmed we will send you the charter operator’s contact details as needed for check-in and embarkation. Any questions or concerns you have can go through us, however.",
  },
  {
    question: "Where can I see my booking details?",
    answer:
      "We will send a confirmation email with all the booking details and your booking number once the booking is confirmed.",
  },
  {
    question: "When can I check-in to my boat?",
    answer:
      "The standard check-in time is 5PM on the Saturday (or other day) of your charter. Most charter companies will allow early check-in for an extra cost. If this is something you are interested in please either let us know or speak with your charter operator directly.",
  },
  {
    question: "Can I check-in earlier than the time stated?",
    answer:
      "As mentioned above, most boat operators will allow early check-in for any additional cost. To see if this is possible for your booking please either let us know so we can speak with the  boar operator, or you can also contact them directly yourself.",
  },
]
