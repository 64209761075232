import React from "react"
import { Stack, Text } from "@chakra-ui/react"

import { Boat, DropPoint, Length, Passenger, Calendar, AC, Draught, Cabin, Beam, WC } from "./Icons"
import { metersToFeetAndInches } from "../domain/units"

const prioritizedList = [
  ["data.base.name", "Starting Base", <DropPoint w={6} h={6} color="primary" />],
  ["data.year", "Year", <Calendar w={6} h={6} color="primary" />],
  ["data.length", "Length", <Length />],
  ["data.berthsTotal", "Capacity", <Passenger w={6} h={6} color="primary" />],
  ["data.cabins", "Cabins", <Cabin w={6} h={6} color="primary" />],
  ["data.wc", "Showers/WC", <WC />],
  ["data.draught", "Draught", <Draught />],
  ["data.beam", "Beam", <Beam />],
  ["equipment.ac", "AC", <AC />],
  ["data.brand", "Brand", <Boat w={6} h={6} color="primary" />],
]

const mapFeatureToVal = (feature, data, equipments) => {
  const f = feature[0].split(".")
  if (f[0] === "data" && f[1] === "base") {
    return data.base.name
  }
  if (f[0] === "data" && f[1] === "length") {
    if (data.length) {
      if (data.lengthUnit === "m") {
        return `${data.length} ${data.lengthUnit} / ${metersToFeetAndInches(data.length)}`
      }
      return `${data.length} ${data.lengthUnit}`
    }
  }
  if (f[0] === "data" && f[1] === "beam") {
    if (data.beam) {
      if (data.beamUnit === "m") {
        return `${data.beam} ${data.beamUnit} / ${metersToFeetAndInches(data.beam)}`
      }
      return `${data.beam} ${data.beamUnit}`
    }
  }
  if (f[0] === "data" && f[1] === "draught") {
    if (data.draught) {
      if (data.draughtUnit === "m") {
        return `${data.draught} ${data.draughtUnit} / ${metersToFeetAndInches(data.draught)}`
      }
      return `${data.draught} ${data.draughtUnit}`
    }
  }
  if (f[0] === "data") {
    return data[f[1]]
  }
  const ac = equipments.filter((e) => e === "Air condition")
  return ac.length > 0 ? "Air Conditioner" : null
}

const mapFeatureToDetailCard = (feature, data, equipments) => {
  const featureLabel = feature[1]
  const featureValue = mapFeatureToVal(feature, data, equipments)
  if (!featureValue) {
    return null
  }

  return (
    <Stack
      align={["start", "center"]}
      direction={["row", "column"]}
      mb={4}
      sx={{
        "span:nth-of-type(1)": {
          color: "primary",
          textTransform: "uppercase",
        },
      }}
      key={`feature ${featureLabel}`}
    >
      {feature[2]}
      <Text w={["10rem", "auto"]} mb={0} color="primary" textTransform="uppercase">
        {featureLabel}
      </Text>
      <Text>{featureValue}</Text>
    </Stack>
  )
}

const BoatFeatures = ({ boatData, start, end }) => {
  return prioritizedList
    .map((f) => mapFeatureToDetailCard(f, boatData, boatData.equipmentList))
    .filter((dc) => dc)
    .slice(start, end)
}

export default BoatFeatures
