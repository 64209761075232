import axios from "axios"
import { useQuery } from "react-query"
import { Offer } from "../types/offer"

const useOffer = (offerId: string) => {
  const fetchOffer = () => axios.get<Offer>("/api/offers-get", { params: { id: offerId } })
  const { data: offerResponse, isLoading: isOfferLoading } = useQuery(["fetchOffer", offerId], fetchOffer, {
    staleTime: 6 * 60 * 60 * 1000,
    enabled: !!offerId,
  })
  const offer = offerResponse?.data
  return { isOfferLoading, offer }
}

export default useOffer
