import React from "react"
import PropTypes from "prop-types"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

import { nonPlanImages } from "../domain/boat"
import Section from "./Section"

const BoatImages = ({ boat }) => {
  const gallerySource = nonPlanImages(boat).map((img, idx) => {
    return {
      original: img,
      originalAlt: `${boat.name} ${idx}`,
      thumbnail: img,
      thumbnailAlt: `${boat.name} thumbnail ${idx}`,
    }
  })

  return (
    <Section
      mt={4}
      sx={{
        ".image-gallery.fullscreen-modal": {
          zIndex: 20,
        },
      }}
    >
      <ImageGallery items={gallerySource} showPlayButton={false} />
    </Section>
  )
}

BoatImages.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  boat: PropTypes.object.isRequired,
}

export default BoatImages
