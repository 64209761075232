import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { Box, Heading } from "@chakra-ui/react"

const ItineraryTitle = ({ steps, title }) => {
  if (typeof title === "string" && title.trim() !== "") {
    return (
      <Heading as="h3" variant="h3" size="lg" mb={6}>
        {title}
      </Heading>
    )
  }
  return (
    <Heading as="h3" variant="h3" size="lg" mb={6}>
      {steps && steps.map((s) => s.itinerary_step).join(" - ")}
    </Heading>
  )
}

ItineraryTitle.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      itinerary_step: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
}

ItineraryTitle.defaultProps = {
  title: "",
}

const Itinerary = ({ itinerary }) => {
  return (
    <Box mb={8}>
      <ItineraryTitle
        steps={itinerary.items}
        title={itinerary.primary.itinerary_step_title}
      />
      <RichText render={itinerary.primary.itinerary_description} />
    </Box>
  )
}

Itinerary.propTypes = {
  itinerary: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        itinerary_step: PropTypes.string,
      })
    ),
  }).isRequired,
}

export default Itinerary
