import React from "react"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const OfferDate = ({ offer }) => {
  if (!offer.checkin) {
    return null
  }

  const from = dayjs(offer.checkin)
  const to = dayjs(offer.checkout)

  return (
    <chakra.span sx={{ color: "muted" }}>
      {`${from.format("MMM, DD")} - ${to.format("MMM, DD YYYY")}`}
    </chakra.span>
  )
}

OfferDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  offer: PropTypes.object.isRequired,
}

export default OfferDate
