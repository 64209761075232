import React from "react"
import PropTypes from "prop-types"
import { Box, Button, chakra, Grid, Heading } from "@chakra-ui/react"

import { Calendar } from "./Icons"
import Link from "./Link"
import { LogoColourX } from "./Logos"
import { PriceTag } from "./PriceTag"
import Section from "./Section"
import Discount from "./Discount"
import OfferDate from "./OfferDate"

// TODO: Clear those when Link and Logos are converted
const CLink = chakra(Link)
const StickyHeader = ({ boat, offer, show }) => {
  return (
    <Section
      sx={{
        display: [show ? "flex" : "none"],
        position: "fixed",
        top: ["initial", 0],
        bottom: [0, "initial"],
        width: "100%",
        backgroundColor: "white",
        py: 6,
        my: 0,
        zIndex: 100,
        boxShadow: [
          (theme) => `0px -1px 1px ${theme.colors.secondaryBg}`,
          (theme) => `0px 1px 1px ${theme.colors.secondaryBg}`,
        ],
        borderTop: [(theme) => `1px solid ${theme.colors.secondaryBg}`, "none"],
      }}
    >
      <Grid
        sx={{
          gridTemplateAreas: [
            `"left_button"`,
            `
          "logo name left_button right_button"
          "logo price left_button right_button"
        `,
          ],
          gridTemplateColumns: ["auto", "2fr 6fr 3fr 3fr"],
          gridColumnGap: [3, 4],
        }}
      >
        <CLink
          to="/"
          className="stickylogo"
          sx={{
            gridArea: "logo",
            display: ["none", "inline"],
            alignSelf: "center",
            height: ["20px", "48px", "48px"],
          }}
        >
          <LogoColourX h={["30px", "48px", "48px"]} w={["73px", "117px", "117px"]} />
        </CLink>
        <Heading as="h4" variant="h4" fontSize="xl" m={0} sx={{ gridArea: "name", display: ["none", "inline"] }}>
          {boat.modelName}
          {offer && <Discount ml={4} fontSize="xl" startPrice={offer.startPrice} price={offer.discountedPrice} />}
        </Heading>
        {offer && (
          <Box sx={{ gridArea: "price", display: ["none", "inline"] }}>
            <OfferDate offer={offer} />
            <chakra.span sx={{ mx: 1, color: "muted" }}> | </chakra.span>
            <PriceTag currency={offer.currency} startPrice={offer.startPrice} price={offer.discountedPrice} />
          </Box>
        )}
        <Button
          gridArea="left_button"
          as={Link}
          size="lg"
          to={offer ? `/boats/${boat.uid}/reserve-details?offerId=${offer.id}` : `/boats/${boat.uid}/reserve-details`}
          leftIcon={<Calendar boxSize={6} display={["none", "inline-block"]} />}
        >
          INQUIRE NOW
        </Button>
      </Grid>
    </Section>
  )
}
StickyHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  boat: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  offer: PropTypes.object,
  show: PropTypes.bool.isRequired,
}

StickyHeader.defaultProps = {
  offer: null,
}

export default StickyHeader
