import * as React from "react"
import { Image } from "@chakra-ui/image"
import { Text } from "@chakra-ui/layout"
import { Offer } from "../lib/types/offer"

interface Props {
  offer: Offer
}

export function SimilarBoat(props: Props) {
  const { boat } = props.offer

  return (
    <a href={`/boats/${boat.uid}?offerId=${props.offer.id}`}>
      <Image src={boat.images[0].url} w="100%" h={200} />
      <Text mt={2} fontWeight="bold">{`${boat.modelName}, ${boat.year}`}</Text>
    </a>
  )
}
